<template>
  <div>
    <v-card>
      <v-card-title>Listado de tipos de Parte</v-card-title>
      <v-card-text>
        <botonera 
          :botones="botones" 
          alinear="right"
          @crear="dialog = !dialog"
        ></botonera>
      </v-card-text>
    </v-card>

    <datatable :cabeceras="tipoPartesListHeaders" :items="tipoPartesListBody" titulo="Listado de Tipos de Partes" label="Escriba Para buscar Tipo de Partes" icono="list" color_icono="azul"
    :tachar="false"
    :acciones="['editar','eliminar']"
    @editar="editTipoParte($event)"
    @eliminar="deleteTipoParte($event)">
    </datatable>

    <!-- <v-data-table 
        :headers="tipoPartesListHeaders"
        :items="tipoPartesListBody"
        class="elevation-1"
        no-data-text="No hay tipos de parte"
        :search="search"
    >
      <template v-slot:[`item.actions`]="{item}">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              @click="editTipoParte(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar Tipo Parte</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              @click="deleteTipoParte(item)"
              v-on="on"
            >
            mdi-delete
            </v-icon>
          </template>
          <span>Eliminar Tipo Parte</span>
        </v-tooltip>
      </template>
    </v-data-table> -->

    <!-- Modal Nuevo Tipo Parte -->
    <v-dialog
      v-model="dialog"
      width="800px"
      @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-col
              class="align-center justify-space-between"
              cols="6"
            >
              <v-text-field
                prepend-icon="mdi-barcode"
                placeholder="Nombre"
                v-model="tipoParte.nombre"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="closeModal"
          >Cerrar</v-btn>
          <v-btn
            color="primary"
            @click="saveTipoParte"
          >Grabar</v-btn>
        </v-card-actions>
              
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>
<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    data: () => ({
      title: 'Nuevo Tipo de Parte',
      color: 'green',
      snackbar: false,
      errors: [],
      validation_message: '',
      success: null,
      search: null,
      file: null,
      dialog: false,
      tipoParteIndex: -1,
      tipoParte: {
        id: 0,
        nombre: '',
      },
      tipoPartesListHeaders: [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'id',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Nombre',
          align: 'center',
          sortable: true,
          value: 'nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: "blue-grey darken-4 white-text"
        },
      ],
      tipoPartesListBody: [],
      botones:[
        {boton:'crear',tooltip:'Nuevo Tipo Partes'},
        //{boton:'cargar',tooltip:'Carga Masiva'},
        //{boton:'eliminar',tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
      this.loadTipoPartes();
    },
    methods:{
      async loadTipoPartes(){
        this.mostrarLoading('Cargando listado de tipos de parte...')
        let url = `${this.base_url}tipo_partes`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.tipoPartesListBody = response.data;
          this.ocultarLoading()
        }).catch((error)=>{
            console.log(error);
            this.ocultarLoading()
        });
      },
      async saveTipoParte(){
        // validation
        this.errors = [];
        if(!this.tipoParte.nombre){
          this.errors.push("Debe indicar nombre");
        }else if(this.tipoParte.nombre.length > 20){
          this.errors.push("Tamaño máximo nombre: 20 caracteres");
        }
        if(this.errors.length > 0){
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let url = `${this.base_url}tipo_partes`;
        let tipoParteData = {
          'nombre': this.tipoParte.nombre,
        };
        if(this.tipoParte.id > 0){
          url = url + "/" + this.tipoParte.id;
          await this.axios.put(url, tipoParteData, this.headers).then((response)=>{
            let index = this.tipoPartesListBody.map(tipoParte => {
              return tipoParte.id;
            }).indexOf(this.tipoParte.id);
            this.tipoPartesListBody.splice(index, 1, response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
            this.validation_message = error;
            this.showSnackBar(true);
          });
        }else{
          await this.axios.post(url, tipoParteData, this.headers).then((response)=>{
            this.tipoPartesListBody.push(response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
            this.errors = error.response.data;
            this.validation_message = this.errors.join();
            this.showSnackBar(true);
          });
        }
        this.cleanForm();
      },
      editTipoParte(item) {
        this.tipoParteIndex = this.tipoPartesListBody.indexOf(item);
        this.tipoParte = Object.assign({}, item);
        this.dialog = true;
        this.title = "Editar Tipo de Parte";
      },
      async deleteTipoParte(item) {
        this.$confirm('Desea borrar este tipo de parte?').then(res => {
          if(res){
            let url = `${this.base_url}tipo_partes/${item.id}`;
            this.axios.delete(url, this.headers).then((response)=>{
              console.log(response);
              const index = this.tipoPartesListBody.indexOf(item);
              this.tipoPartesListBody.splice(index, 1);
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      closeModal(){
        this.cleanForm();
        this.dialog = false;
        this.error = null;
        this.title = "Nuevo Tipo de Parte";
      },
      cleanForm(){
        this.tipoParte = {
          id: 0,
          nombre: '',
        };
        this.dialog = false;
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers']),
      
    },
    components:{
      
    }
  }
</script>